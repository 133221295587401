import React from 'react';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { Button } from '@hagerty/react-components';

import { Translate } from 'src/components/Translate';
import { Membership } from 'src/components/benefits/useBenefitsPageQueries';
import { useAuthContext } from 'src/components/AuthProvider';
import useJoinTheClubLink from 'src/hooks/useJoinTheClubLink';
import { useMfaLogin } from 'src/hooks/use-mfa-login';

import styles from './CTAs.module.scss';

type ButtonsProps = {
  size?: 'large';
  children: JSX.Element | JSX.Element[];
};

export const Buttons: React.FC<ButtonsProps> = ({ size, children }) => (
  <div className={cx(styles.buttons, styles[size])}>{children}</div>
);

type JoinTheClubProps = {
  ready: boolean;
  membership?: Membership;
  isLarge?: boolean;
};

export const JoinTheClub: React.FC<JoinTheClubProps> = ({ ready, membership, isLarge }): JSX.Element => {
  const linkTo = useJoinTheClubLink();

  if (!ready || !!membership) return null;

  return (
    <div className="mr-6">
      <Link
        to={linkTo}
        className={cx('button button_primary', { button_primary_large: isLarge })}
        data-cy="non-member-join"
      >
        <Translate resourceKey="partner-offers.details.join-the-club-button" />
      </Link>
    </div>
  );
};

type LoginProps = {
  isLarge?: boolean;
};

export const LogIn: React.FC<LoginProps> = ({ isLarge }) => {
  const { isAuthenticated, loading } = useAuthContext();
  const login = useMfaLogin();

  return (
    !loading &&
    !isAuthenticated && (
      <Button buttonType="Outline" buttonSize={isLarge ? 'Large' : 'Default'} onClick={login} testId="anonymous-login">
        <Translate resourceKey="partner-offers.details.log-in-button" />
      </Button>
    )
  );
};

type RedeemNowProps = {
  // The redeem code if any
  method: 'code' | 'auto';

  // the url to redeem the offers
  url: string;

  // The button text to show
  buttonText?: string;

  // The boolean to determine if membership is loaded
  ready: boolean;

  // The membership to determine if button should render
  membership?: Membership;
};

export const Redeem: React.FC<RedeemNowProps> = ({ method, url, buttonText, ready, membership }) => {
  const { isAuthenticated } = useAuthContext();
  const { t } = useTranslation();
  let text = '';

  if (buttonText) {
    text = t(buttonText);
  } else {
    text = t(
      method === 'code' ? 'partner-offers.details.redeem-with-code-button' : 'partner-offers.details.redeem-now-button'
    );
  }

  return (
    url &&
    ready &&
    isAuthenticated &&
    membership && (
      <a href={url} className="button button_primary" data-cy="member-redeem">
        {text}
      </a>
    )
  );
};
