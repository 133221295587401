import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import marked from 'marked';
import { Assets, AssetTitle, Benefit } from './Redemption.types';
import { toContentfulCDN } from 'src/helpers/toContentfulCDN';
import { getEmMarkup } from 'src/helpers/parseMarkdown';

export const parseBenefits = (markdown: string): Benefit[] => {
  const tokens = marked.lexer(markdown);
  const benefits: Benefit[] = [];

  for (const token of tokens) {
    switch (token.type) {
      case 'heading': {
        benefits.push({
          title: token.tokens[0].type === 'em' ? getEmMarkup(token.text) : token.text,
          isTitleHtml: token.tokens[0].type === 'em' ? true : false,
          description: null,
          image: null,
          cypressData: null,
        });
        break;
      }
      case 'paragraph': {
        if (token.tokens[0].type === 'image') {
          const image = (token.tokens[0] as marked.Tokens.Image).href;
          benefits[benefits.length - 1].image = toContentfulCDN(image);
        } else if (token.tokens[0].type === 'text') {
          benefits[benefits.length - 1].description = token.tokens[0].text;
        }
        break;
      }
    }
  }

  return benefits;
};

const useRedemptionAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo<Assets>((): Assets => {
    return {
      benefits: parseBenefits(t(AssetTitle.Benefits)),
      hdcReferralsBenefits: parseBenefits(t(AssetTitle.HdcReferralsBenefits))
    };
  }, [ready]);
};

export default useRedemptionAssets;
